import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';

const formatDate = (timestamp: { _seconds: number, _nanoseconds: number } | Timestamp | any): string => {
  try {
    let date;
    if (timestamp instanceof Timestamp) {
      date = timestamp.toDate();
    } else if (timestamp && typeof timestamp._seconds === 'number' && typeof timestamp._nanoseconds === 'number') {
      date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    } else if (timestamp instanceof Date) {
      date = timestamp;
    } else {
      date = new Date(timestamp);
    }

    if (isNaN(date.getTime())) {
      // throw new Error('Invalid date');
    }

    return format(date, 'yyyy-MM-dd hh:mma'); // format date as desired
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};


export {
  formatDate
}