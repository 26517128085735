import React from "react";
import LandingLayout from "../layouts/landing";
import LandingNav from "../components/LandingNav";
import { ArrowRight, Check, CheckCircle } from "lucide-react";
import { ROUTES } from "../routes/const";

const LandingPricing = () => {
  return (
    <LandingLayout>
      <>
        <LandingNav inView={true} />
        {/* create a beautiful pricing page with free plan blaze plan */}
        <main className="bg-white mt-[72px] min-h-screen">
          <div className="container mx-auto px-3 py-10">
            <section>
              <div className="text-center">
                <h1 className="text-3xl font-bold mb- Hero text-black">
                  Select Your Right Plan!
                </h1>
                <p className="text-gray-500 Hero mt-3 font-medium">
                  We have a plan that will suit your business. Choose a plan
                  that suits your business needs.
                </p>
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-start gap-5 mt-10">
                <div className="w-full lg:w-4/12 bg-gray-100 p-5 rounded-lg hover:bg-orange-100 transition-colors ease-in-out duration-300 cursor-pointer">
                  <h2 className="text-2xl font-bold text-black text-center">
                    Free Plan
                  </h2>
                  <p className="text-center text-gray-500 mt-3">
                    Suitable for small businesses and startups 
                  </p>
                  <ul className="mt-10">
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        <span className="text-black font-medium">
                          Free 20 SMS{" "}
                        </span>
                        unit, then purchase extra SMS on demand
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        <span className="text-black font-medium">
                          Unlimited{" "}
                        </span>
                        Users
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        <span className="text-black font-medium">
                          Unlimited{" "}
                        </span>
                        Administrators
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        <span className="text-black font-medium">
                          Unlimited{" "}
                        </span>
                        Collaborators
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        Onboard up to{" "}
                        <span className="text-black font-medium">
                          20 customers{" "}
                        </span>
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        Record
                        <span className="text-black font-medium">
                          {" "}
                          50 transactions{" "}
                        </span>
                        per day
                      </div>
                    </li>
                  </ul>
                  <div className="text-center mt-5">
                    <h1 className="text-3xl font-bold text-black">GHS0.00/mo</h1>
                    <p className="text-gray-500">No cost</p>
                  </div>
                  <div className="text-center mt-5">
                    <a
                      href={ROUTES.login}
                      className="mb-5 flex justify-center"
                      style={{ alignItems: "center" }}
                    >
                      <button className="btn bg-black text-white px-8 sm:px-10 py-3 rounded-full hover:bg-black hover:text-yellow-500 transition ease-out duration-300 flex items-center Hero">
                        Get Started
                        <ArrowRight size={15} className="ml-2" />
                      </button>
                    </a>
                  </div>
                </div>
                <div className="w-full lg:w-4/12 bg-gray-100 p-5 rounded-lg hover:bg-orange-100 transition-colors ease-in-out duration-300 cursor-pointer">
                  <h2 className="text-2xl font-bold text-black text-center">
                    Blaze Plan
                  </h2>
                  <p className="text-center text-gray-500 mt-3">
                    Pay as you go plan. You only pay for what you use.
                  </p>

                  <ul className="mt-10">
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        SMS unit - Purchase extra SMS on demand plus{" "}
                        <span className="text-black font-medium">
                          20 Free SMS{" "}
                        </span>{" "}
                        on every purchase
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        <span className="text-black font-medium">
                          Unlimited{" "}
                        </span>
                        Users
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        <span className="text-black font-medium">
                          Unlimited{" "}
                        </span>
                        Administrators
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        <span className="text-black font-medium">
                          Unlimited{" "}
                        </span>
                        Collaborators
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        Customers - No cost up to{" "}
                        <span className="text-black font-medium">
                          20 customers{" "}
                        </span>{" "}
                        , then{" "}
                        <span className="text-black font-medium">
                          GHS0.10/customer{" "}
                        </span>{" "}
                      </div>
                    </li>
                    <li className="flex items-start mb-3">
                      <div>
                        <Check size={30} className="text-green-500" />
                      </div>
                      <div className="ml-3">
                        Transactions - No cost up to{" "}
                        <span className="text-black font-medium">
                          {" "}
                          50 transactions per day{" "}
                        </span>{" "}
                        , then{" "}
                        <span className="text-black font-medium">
                          {" "}
                          GHS0.15/transaction
                        </span>{" "}
                      </div>
                    </li>
                  </ul>

                  <div className="text-center mt-5">
                    <a
                      href={ROUTES.login}
                      className="mb-5 flex justify-center"
                      style={{ alignItems: "center" }}
                    >
                      <button className="btn bg-black text-white px-8 sm:px-10 py-3 rounded-full hover:bg-black hover:text-yellow-500 transition ease-out duration-300 flex items-center Hero">
                        Get Started
                        <ArrowRight size={15} className="ml-2" />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    </LandingLayout>
  );
};

export default LandingPricing;
