import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { getLoans } from '../services/core-api'; // Adjust import path as needed
import { decryptValue, isEncrypted } from '../utils/encryptvalue';

// Define the shape of the loan data

interface LoanProp {
    fullName: string;
    loanAmount: number
    interestRate: string
    depositAmount: string;
    accountNumber: string;
    phone: string;
    accountBalance: string;
    agentEmail: string;
    address: string;
    DOB: string;
    gender: string;
    agentName: string;
    agentID: string;
    date: string;
    approved: boolean;
    savingsType: string;
    nationalId: string;
    createdAt: string;
  }


// Define the context type
interface LoanDataContextProps {
    loans: LoanProp[];
    setLoans: React.Dispatch<React.SetStateAction<LoanProp[]>>;
    customersAddedToday: LoanProp[]; // Assuming this holds loans added today
    loading: boolean; // Loading state
    refreshLoans: () => void; // Function to refresh loan data
    setCustomersAddedToday: React.Dispatch<React.SetStateAction<LoanProp[]>>;
}

// Create the context
const LoanDataContext = createContext<LoanDataContextProps | null>(null);

// Provider component
export const LoanDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [loans, setLoans] = useState<LoanProp[]>([]);
    const [customersAddedToday, setCustomersAddedToday] = useState<LoanProp[]>([]);
    const [loading, setLoading] = useState<boolean>(false); // Loading state

    const getLoanData = async () => {
        const email = auth.currentUser?.email;
        if (email) {
            setLoading(true); // Start loading
            try {
                const loansData = await getLoans(email);
                const decryptedCustomersData = loansData.map((customer: any) => ({
                    ...customer,
                    nationalId: isEncrypted(customer.nationalId) ? decryptValue(customer.nationalId) : 'Please update ID',
                  }));

                setLoans(decryptedCustomersData);

                // Filter loans created today
                const today = new Date().toDateString();
                const todayLoans = decryptedCustomersData.filter((loan: LoanProp) => loan.date === today);
                setCustomersAddedToday(todayLoans);
            } catch (error) {
                // console.error('Error fetching loans:', error);
                // Handle errors accordingly, e.g., set empty state
                setLoans([]);
                setCustomersAddedToday([]);
            } finally {
                setLoading(false); // Stop loading
            }
        } else {
            // console.log('No user is signed in');
            setLoans([]);
            setCustomersAddedToday([]);
        }
    };

    // Fetch loan data on mount
    useEffect(() => {
        getLoanData();
    }, []);

    return (
        <LoanDataContext.Provider value={{ loans, setCustomersAddedToday, customersAddedToday, setLoans, loading, refreshLoans: getLoanData }}>
            {children}
        </LoanDataContext.Provider>
    );
};

// Custom hook to use the LoanDataContext
export const useLoanDataContext = () => {
    const context = useContext(LoanDataContext);
    if (!context) {
        throw new Error('useLoanDataContext must be used within a LoanDataProvider');
    }
    return context;
};
