import { Title } from "@mantine/core";
import React, { useEffect } from "react";
import Logo from '../assets/applogo.svg'
import { Loader } from '@mantine/core';
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes/const";


export default function AppLoader() {
  const {user} = useUser()
  const navigate = useNavigate()
  useEffect(()=> {
    if(user){
     navigate(ROUTES.home)
    }
   }, [user])
   
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#000", // e9f2f1
        color: "#fff",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
        <div></div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: '#000'}}>
      <img src={Logo} width={200} alt="logo" style={{ marginBottom: 20}}/>

    </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: '#000', marginBottom: 30}}>
       <Loader size={20} color="white" mb={50}/>

       <small>Simplifying micro transactions for smarter finance</small>
        <small style={{ color: '#abababde', marginTop: 20}}>© { new Date().getFullYear()} DigiCollect.  All rights reserved</small>
      </div>
    </div>
  );
}
