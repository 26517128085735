import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { totalDailyBalance } from '../services/core-api'; // Adjust import path as needed

// Define the shape of the total daily balance data
interface DailyTotal {
  total_daily_transactions: number;
  total_deposits: number;
  total_withdrawals: number;
}

// Define the context type
interface DailyTotalBalanceContextProps {
  dailyTotal: DailyTotal;
  loading: boolean
  refreshDailyTotal: () => void; // Function to refresh the daily total
}

// Create the context
const DailyTotalBalanceContext = createContext<DailyTotalBalanceContextProps | null>(null);

// Provider component
export const DailyTotalBalanceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dailyTotal, setDailyTotal] = useState<DailyTotal>({
    total_daily_transactions: 0,
    total_deposits: 0,
    total_withdrawals: 0,
  });
  const [loading, setLoading] = useState(false)

  const dailyTotalBalance = async () => {
    setLoading(true)
    const email = auth.currentUser?.email;
    if (email) {
      try {
        const balance = await totalDailyBalance(email);
        if (balance) {
          setDailyTotal(balance);
         setLoading(false)
        }
      } catch (err) {
        console.error(err);
        setLoading(false)
        setDailyTotal({
          total_daily_transactions: 0,
          total_deposits: 0,
          total_withdrawals: 0,
        });
      }finally{
        setLoading(false)
      }
    }
  };

  // Optional: Refresh daily total on mount
  useEffect(() => {
    dailyTotalBalance();
  }, []);

  return (
    <DailyTotalBalanceContext.Provider value={{ dailyTotal, loading, refreshDailyTotal: dailyTotalBalance }}>
      {children}
    </DailyTotalBalanceContext.Provider>
  );
};

// Custom hook to use the DailyTotalBalanceContext
export const useDailyTotalBalanceContext = () => {
  const context = useContext(DailyTotalBalanceContext);
  if (!context) {
    throw new Error('useDailyTotalBalanceContext must be used within a DailyTotalBalanceProvider');
  }
  return context;
};
