import {
  AppShell,
  Avatar,
  Burger,
  Group,
  Paper,
  Skeleton,
  Text,
  Title,
  Badge,
  Indicator,
  Divider,
  Card,
  Button,
  Alert
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import Home from "../views/dashboard";
import Agents from "../views/agents";
import UserMenu from "./UserMenu";
import { useNavigate } from "react-router-dom";
import {
  IconArrowIteration,
  IconBrandSuperhuman,
  IconCreditCard,
  IconDashboard,
  IconLayoutDashboard,
  icons,
  IconSettings,
  IconTransfer,
  IconUser,
  IconUsersGroup,
  IconUsers,
  IconCoinTakaFilled,
  IconCoin,
  IconCheck,
  IconCircleDashedCheck,
  IconMoneybag,
  IconArrowsExchange,
  IconGraph,
  IconReport,
  IconInfoCircle,
} from "@tabler/icons-react";
import Customers from "../views/customers";
import Settings from "../views/settings";
import Profile from "../views/profile";
import { ROUTES } from "../routes/const";
// import useAdminStatus from "../hooks/useAdminStatus";
import useCollaboratorStatus from "../hooks/useCollaboratorStatus";
import Approvals from "../views/approvals";
// import Logo from '../assets/applogo1.svg'
import { getPendingCustomers, getPendingWithdrawals } from "../services/core-api";
import Subscription from "../views/subscription";
import Withdrawals from "../views/withdrawals";
import useAdminOrgId from "../hooks/useAdminOrgId";
import { Transactions } from "../views/transactions";
import Loans from "../views/loans";
import { OrgInfoProvider, useOrgInfo } from "../context/orgContext";
import ComapnyLogo from "./ComapanyLogo";
import CompanyLogo from "./ComapanyLogo";
import { useAdminStatusContext } from "../context/adminContext";
import { useCustomerApprovalContext } from "../context/customerApprovalContext";
import { useWithdrawalApprovalContext } from "../context/withdrawalApprovalContext";
import Report from "../views/report";
import { useUser } from "../context/UserContext";
import { formatDate } from "../utils/formatDate";
import InfoBanner from "./infoBanner";
  // const isProd = process.env.NODE_ENV === 'production'
  const isDev = process.env.NODE_ENV === 'development'

export function DashboardSkeleton() {
  const [opened, { toggle }] = useDisclosure();
  const [selectedPage, setSelectedPage] = useState("Dashboard");
  const [approvalAvailable, setApprovalAvailable]= useState<number>(0)
  const {isAdmin} = useAdminStatusContext()
  const { customers } = useCustomerApprovalContext()
  const {pendingWithdrawals} = useWithdrawalApprovalContext()
  const {user} = useUser()
  const {plan} = useOrgInfo()
 

  const isCollaborator = useCollaboratorStatus()
  const navigate = useNavigate();
  const sideMenu = [
    { title: "Dashboard", icon: <IconLayoutDashboard />},
    // { title: "Deposit", icon: <IconTransfer />,  adminOnly: true, },
    { title: "Transactions", icon: <IconArrowsExchange />, adminOnly: true },
    // { title: "Withdrawals", icon: <IconCoin />, adminOnly: true },
    { title: "Loans", icon: <IconMoneybag />, adminOnly: true },
    { title: "Agents", icon: <IconUsers /> , adminOnly: true},
    { title: "Customers", icon: <IconUsersGroup /> , adminOnly: true},
    { title: "Approvals", icon: <IconCircleDashedCheck/>, adminAndCollaboratorOnly: true },
    { title: "Account", icon: <IconUser /> },
    { title: "Report", icon: <IconReport />, adminOnly: true }
    ];

  const sideMenu2 = [
    { title: "Settings", icon: <IconSettings />, adminOnly: true },
    { title: "Subscription", icon: <IconCreditCard />, adminOnly: true },
  ]

  const filterSideMenu2 = sideMenu2.filter(item=> {
    if(isAdmin){
      return isAdmin
    }else{
      return !item.adminOnly
    }
  })

  const filteredMenu = sideMenu.filter(item => {
    // if(isProd && isAdmin) {
    //   return !item.prod
    // }
    if(isAdmin){
      return isAdmin
    }else if(isCollaborator){
      return !item.adminOnly
    }
    else{
      return !item.adminOnly && !item.adminAndCollaboratorOnly
    }
  } ); //filter the menu

  const renderComponent = () => {
    switch (selectedPage) {
      case "Dashboard":
        return <Home />;
      case "Transactions":
        return <Transactions />;
      case "Loans":
        return <Loans />;
      case "Approvals":
          return <Approvals />;
      case "Agents":
        return <Agents />;
      case "Customers":
        return <Customers />;
      case "Subscription":
        return <Subscription />;
      case "Settings":
        return <Settings />;
      case "Account":
        return <Profile />;
        case "Report":
        return <Report />;
      default:
    }
  };

  useEffect(()=> {
    const navigateToSubscriptionScreenOnExpiredPlan = ()=> {
      if( plan.payAsYouGo && plan.planExpired){
        setSelectedPage('Subscription')
      }
    }
    navigateToSubscriptionScreenOnExpiredPlan()
  }, [plan.planExpired])
  

  useEffect(()=> {
    getApprovalData()
  }, [customers || pendingWithdrawals])



  const getApprovalData = async () => {
    const email = user?.email
    if(email){
      const pendingCustomer = customers
      const pendingWithdrawal = pendingWithdrawals 

      if (pendingCustomer.length >= 0 || pendingWithdrawal.length >= 0) {
        const totalApprovals = pendingCustomer.length  + pendingWithdrawal.length
        setApprovalAvailable(totalApprovals);
      }
    }
    return
  };

  return (
    <AppShell
      header={{ height: 0 }}
      navbar={{ width: 230, breakpoint: "sm", collapsed: { mobile: !opened } }}
      padding="md"
    >
      <InfoBanner info="Loan option removed for an important fix: Loan account can't be created at the moment"/>
   
      {/* <AppShell.Header bg={"#fff"}>
        <Group
          h="100%"
          px="md"
          justify="space-between"
          style={{ display: "flex" }}
        >
          <div className="mobileNav">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            <CompanyLogo />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <UserMenu />
          </div>
        </Group>
      </AppShell.Header> */}
{/* 
         <div style={{ backgroundColor: 'red', width: '100%', padding: 5, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
          <div>
          <Text c={'#fff'} fw={'bold'}>Important Update: Download app v1.1.26 to Ensure Smooth Performance!</Text>
          <small style={{ color: '#fff'}}>Click accounts and select "Download mobile app tab", then click on "Download App" button to download updated version </small>
          </div>
          </div> */}

      <Group
          h="100%"
          px="md"
          justify="space-between"
          p={20}
          style={{ display: "flex", backgroundColor: "#f9f9f7" }}
        >
       
          <div className="mobileNav">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />

          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <UserMenu />
          </div>
        </Group>

      <AppShell.Navbar p="sm" withBorder={false} bg={'#000'} h={'100%'} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column',  overflow: 'hidden', flexGrow: 1, overflowY: 'auto'}}>
        <div>
        <CompanyLogo />
        
        <AppShell.Section mt={20}>
        {filteredMenu.map((list, index) => (
          // <Skeleton key={index} h={30} mt="sm" animate={false} color='#000'>{list.title}</Skeleton>
           <Button
           disabled={plan.payAsYouGo && plan.planExpired}
            onClick={() =>{
              setSelectedPage(list.title)
              toggle()
            }}
            bg={selectedPage === list.title ? "#ffffff" : plan.payAsYouGo && plan.planExpired ? "#1f1f1f" :  "none"}
            className="sideMenuHover"
            style={{
              display: "flex",
              cursor: "pointer",
              // justifyContent: "space-between",
              alignItems: "center",
              // paddingInline: "20px",
              fontSize: '14px',
              color: selectedPage === list.title ? "#000" : "#fff",
            }}
            fullWidth
            // h={35}
            mt="xs"
            // p={'sm'}
            key={index}
            leftSection={list.icon}
            rightSection={list.title === 'Approvals' && approvalAvailable >= 1 &&  <Indicator ml={30} inline label={approvalAvailable} size={16} processing color='green'></Indicator>}
          >
            
            {/* <div style={{ display: 'flex', alignItems: 'center'}}>{list.icon} &nbsp; {list.title}</div> */}
            {list.title}
            {/* {list.title === 'Report' && <Badge color="#000" size="sm">New</Badge>} */}  
            
          </Button>
       
        ))}
           </AppShell.Section>
        </div>

        <AppShell.Section mb={10} mt={10}>
          <Divider size='xs' color="#4e4e4e"/>
        {filterSideMenu2.map((list, index) => (
          // <Skeleton key={index} h={30} mt="sm" animate={false} color='#000'>{list.title}</Skeleton>
          <Paper
            onClick={() =>{
              setSelectedPage(list.title)
              toggle()
            }}
            bg={selectedPage === list.title ? "#ffffff" : "none"}
            className="sideMenuHover"
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: "20px",
              fontSize: '14px',
              color: selectedPage === list.title ? "#000" : "#ffffff",
            }}
            h={35}
            mt="xs"
            p={'sm'}

            key={index}
          >
            <div style={{ display: 'flex'}}>{list.icon} &nbsp; {list.title}</div>
            
          </Paper>
        ))}

        {/* <Group p={20} mt={20}>
        <small style={{ fontWeight: 'bolder', lineHeight: 0.1, color: '#fff', fontSize: '10px'}}>{`© DigiCollect ${new Date().getFullYear()}`}</small>
        <small style={{ fontSize: '10px', color: '#fff'}}>Transforming micro transactions for smarter finance</small>
        </Group> */}
        </AppShell.Section>



        <AppShell.Section mb={10} mt={10}>
          <Divider size='xs' color="#4e4e4e"/>
  

  {isAdmin && <Card
          bg={'dark'}
            shadow="none"
            withBorder={false}
            mt={10}
          padding="sm"
           style={{ alignItems: 'flex-start'}}>
              
             <Badge variant="light" mb={5} color={plan.payAsYouGo ? 'green' : 'orange'} fw={'bolder'} size="md">
              {plan.payAsYouGo ? 'Blaze' : 'Free'}
      </Badge>
      {/* <Badge mb={10} color={plan.payAsYouGo? 'green' : 'red'} variant="light"> {plan.payAsYouGo ? 'Pay as you go': 'Trial'}</Badge> */}
      <Text c={'dimmed'} size="xs" mb={10}>
          You are currently on {plan.payAsYouGo ? 'Pay as you go' : 'Free'} plan
      </Text>
      <Button size="xs" color="#000" onClick={()=> setSelectedPage('Subscription')} disabled={plan.payAsYouGo ? true : false}>Upgrade</Button>
          </Card>}
          
        </AppShell.Section>
     
      </AppShell.Navbar>
      <AppShell.Main style={{ backgroundColor: "#f9f9f7" }}>
        {renderComponent()}
      </AppShell.Main>
    </AppShell>
  );
}
