import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { createCustomerProp, create_Customer, getCustomers } from '../services/core-api'; // Adjust import path as needed
import { decryptValue, isEncrypted } from '../utils/encryptvalue';

// Define the shape of the customer data
export interface Customer {
    fullName: string;
    loanAmount: number;
    interestRate: string;
    depositAmount: string;
    accountNumber: string;
    phone: string;
    accountBalance: string;
    agentEmail: string;
    address: string;
    DOB: string;
    gender: string;
    agentName: string;
    agentID: string;
    date: string; // Assuming this is stored as a string in YYYY-MM-DD format
    approved: boolean;
    savingsType: string;
    nationalId: string;
    createdAt: string; // Assuming createdAt is in string format
}

// Define the context type
interface CustomerDataContextProps {
    customers: Customer[];
    setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
    customersAddedToday: Customer[];
    loading: boolean; // Add loading state to context
    refreshCustomers: () => void; // Function to refresh customers data
}

// Create the context
const CustomerDataContext = createContext<CustomerDataContextProps | null>(null);

// Provider component
export const CustomerDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [customersAddedToday, setCustomersAddedToday] = useState<Customer[]>([]);
    const [loading, setLoading] = useState<boolean>(false); // Loading state

    const getCustomersData = async () => {
        const email = auth.currentUser?.email;
        if (email) {
            setLoading(true); // Start loading
            try {
            const customersData = await getCustomers(email);
            const decryptedCustomersData = customersData.map((customer: Customer) => ({
                ...customer,
                nationalId: isEncrypted(customer.nationalId) ? decryptValue(customer.nationalId) : 'Please update ID',
              }));
              
              setCustomers(decryptedCustomersData);
             
                // Filter customers created today
                const today = new Date().toDateString();
                const todayCustomers = decryptedCustomersData.filter((client: Customer) => client.date === today);
                setCustomersAddedToday(todayCustomers);
            } catch (error) {
                console.error('Error fetching customers:', error);
                // Handle errors accordingly, e.g., set empty state
                setCustomers([]);
                setCustomersAddedToday([]);
            } finally {
                setLoading(false); // Stop loading
            }
        } else {
            console.log('No user is signed in');
            setCustomers([]);
            setCustomersAddedToday([]);
        }
    };

    // Fetch customers data on mount
    useEffect(() => {
        getCustomersData();
    }, []);

    return (
        <CustomerDataContext.Provider value={{ customers, customersAddedToday, setCustomers, loading, refreshCustomers: getCustomersData }}>
            {children}
        </CustomerDataContext.Provider>
    );
};

// Custom hook to use the CustomerDataContext
export const useCustomerDataContext = () => {
    const context = useContext(CustomerDataContext);
    if (!context) {
        throw new Error('useCustomerDataContext must be used within a CustomerDataProvider');
    }
    return context;
};
