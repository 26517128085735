import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { doc, getDocs, addDoc, setDoc, collection, query, where, orderBy } from 'firebase/firestore';
import { Button, Table, Badge, Select, Loader, Title, Paper } from '@mantine/core';
import { IconFileExcel, IconFilter, IconRefresh } from '@tabler/icons-react';
import InputText from '../components/InputText';
import { formatCurrency } from '../utils/currencyFormat';
import { formatDate } from '../utils/formatDate';
import { handleExport } from '../services/core-api';
import { useTransactionDataContext } from '../context/TransactionsContext';
import PaginatedTable from '../components/TransactionsTable';
import TransactionsTable from '../components/TransactionsTable';

export default function Deposit() {
  // const [transactions, setTransactions] = useState<transactionsType[]>([]);
  // const [originalTransactions, setOriginalTransactions] = useState<transactionsType[]>([]);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isloading, setIsloading] = useState(false);
  const [customerName, setCustomerName]= useState('')
const {depositTransactions, setDTransactions, loading, refreshTransactions} = useTransactionDataContext()

interface Transaction {
  id: string;
  customer: string;
  amount: string;
  accountNumber: string;
  date: string;
  agentName: string;
  agentID: string;
  transactionsMethod: string;
  agentEmail: string;
  phone: string;
  createdAt: any;
  approved: boolean;
  transactionType: string;
}

  const filterData = () => {
    let filtered = depositTransactions;
 
    if (search) {
      filtered = filtered.filter(trans =>
        trans.accountNumber.toLowerCase().includes(search.toLowerCase())
      );
    }
  
    if (customerName) {
      filtered = filtered.filter(trans =>
        trans.customer.toLowerCase().includes(customerName.toLowerCase())
      );
    }
  
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);       
      filtered = filtered.filter(trans => {
        const transactionDate = new Date(trans.createdAt.seconds * 1000);
        return transactionDate >= start && transactionDate <= end;
      });
    }
    

    setDTransactions(filtered);
  };

  const downloadData = () => {
    if(depositTransactions.length <= 0) {
      alert("No data to export")
      return
    }
    setIsloading(true);
    handleExport(depositTransactions ? depositTransactions : [])
      .then(() => {
        setIsloading(false);
      })
      .catch(err => {
        console.log(err);
        setIsloading(false);
      });
  };


  const uniqueAccountNumbers = Array.from(
    new Set(depositTransactions?.map(data => data.accountNumber))
  );

  const uniqueCustomerName = Array.from(
    new Set(depositTransactions?.map(data => data.customer))
  );
     // Calculate the total amount of Transactions
     const totalTransactionsAmount = depositTransactions.reduce((sum: number, trans: Transaction) => sum + parseFloat(trans.amount), 0);


  return (
    <>
     <Paper
      withBorder
      p={20}
      >
        <div  style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: 'wrap',
        }}>
           
    
         <div>
         <Title mb={5} order={5} style={{ fontWeight: 'normaL'}}> Total Deposit Balance</Title>
         <Title mb={10} order={2}> {formatCurrency(totalTransactionsAmount, 'GHS')}</Title>
         </div>

         <div>
         <Title mb={5} order={5} style={{ fontWeight: 'normaL'}}> Total Deposits</Title>
         <Title mb={10} order={2}> {depositTransactions.length}</Title>

         </div>


         <div></div>

        </div>
  


       <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 20}}>
       <div style={{ display: 'flex', width: '70%', flexWrap: 'wrap'}}>
       <Button className='removeOnMobile' variant='outline' loading={isloading} disabled={isloading} mr={10} color='#274941' onClick={downloadData}>
          <IconFileExcel /> &nbsp; Export to XLSx
        </Button>
  
       <InputText
         name='State date'
          width='20%'
          type='date'
          value={startDate}
          onChange={(e:any) => setStartDate(e.target.value)}
          placeholder='Start Date'
        />
        <InputText
         name='End date'
         width='20%'
          type='date'
          value={endDate}
          onChange={(e: any) => setEndDate(e.target.value)}
          placeholder='End Date'
        />

<Button leftSection={<IconFilter size={20}/>} variant='default' color='#274941' onClick={filterData} style={{ marginInline: 10 }}>
          Filter Date
        </Button>
       </div>


        <Button loading={loading} variant='solid' disabled={loading} mr={10} bg='#000' onClick={() => { refreshTransactions()
          setStartDate('')
          setCustomerName('')
          setSearch('')
          setEndDate('')
        }}>
         Refresh 
        </Button>
       </div>
   
        
      
        <div className='tableOnMobile'>
        <TransactionsTable data={depositTransactions} dataType='deposit' />
         </div>
        </Paper>
       
     
    
    </>
  );
}
