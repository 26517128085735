import React, { useEffect, useState } from "react";
import { getDoc, getDocs, collection } from "firebase/firestore";
import { db, auth } from "../firebase";
import { Badge, Loader, Pagination, Paper, Table, TextInput, Title, rem } from "@mantine/core";
import { approve_customer, approve_withdrawal, ApproveCustomer, approveWithdrawal, calculateWithdrawalBalance, create_Customer, createUser, delete_customer, delete_pending_withdrawal, deleteCustomerProp, deletePendingWithdrawalProp,  getCustomers, getPendingCustomers, getPendingWithdrawals } from "../services/core-api";
import { getDomainFromEmail } from "../utils/getUserDomain";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import InputText from "../components/InputText";
import { formatCurrency } from "../utils/currencyFormat";
import {
  IconTrash,
  IconEdit,
  IconSettings,
  IconUsers,
  IconTransfer,
  IconRefresh
} from "@tabler/icons-react";
import {ApproveModal, DeleteModal} from "../components/DeleteModal";
// import useAdminStatus from "../hooks/useAdminStatus";
import { Tabs } from '@mantine/core';
import { formatDate } from "../utils/formatDate";
import { useWithdrawalApprovalContext } from "../context/withdrawalApprovalContext";
import { useUser } from "../context/UserContext";
import notify from "../utils/notify";



export default function WithdrawalApproval() {
  // const [pendingWithdrawal, setPendingWithdrawal] = useState<customerProp[]>([]);
  const [search, setSearch] = useState("");
  const [isloading, setIsloading] = useState(false);
  const {user} = useUser();
  const [ApproveOpen, setApproveOpen] = useState(false)
  const [deleteApproveOpen, setDeleteApproveOpen] = useState(false)
  const [processApprove, setProcessApprove]= useState(false)
  const [deleteApproveLoading, setDeleteApproveLoading]= useState(false)
  const [accountToApprove, setAccountToApprove] = useState('')
  const [withdrawData, setWithdrawData] = useState({
    id: '',
    accountNumber: '',
    withdrawalAmount: '',
    accountBalance: ''
  })
const {pendingWithdrawals, setPendingWithdrawals, loading, refreshWithdrawalApprovals} = useWithdrawalApprovalContext()

  // const getWithdrawalApprovalData = async () => {
  //   const email = auth.currentUser?.email
  //   if(email){
  //     const withdrawal = await getPendingWithdrawals(email)
  //     setPendingWithdrawal(withdrawal)
  //   }
  //   return
  // };


  function openDeleteApproveModal(id: string, accountNumber: string, withdrawalAmount: string, accountBalance: string){
    setDeleteApproveOpen(true)
  setAccountToApprove(accountNumber)
  setWithdrawData({
    id: id,
    accountNumber: accountNumber,
    withdrawalAmount: withdrawalAmount,
    accountBalance: accountBalance
  });
  // console.log(withdrawData)
  // console.log(accountToApprove)
}

const deletePendingWithdrawal = async (id: string, accountNumber: string) => {
  setDeleteApproveLoading(true)
  const deletePendingWithdrawalData: deletePendingWithdrawalProp = {
    id: id,
    agentEmail: user?.email || '',
    accountNumber: accountNumber
  }
  try{
    const response  = await  delete_pending_withdrawal(deletePendingWithdrawalData)
    notify(response.message)
    refreshWithdrawalApprovals()
    setDeleteApproveOpen(false)
    setDeleteApproveLoading(false)
  }catch(err){
  console.log(err)
  setDeleteApproveOpen(false)
    setDeleteApproveLoading(true)
  }
  return
}


    function openApproveModal(id: string, accountNumber: string, withdrawalAmount: string, accountBalance: string){
        setApproveOpen(true)
      setAccountToApprove(accountNumber)
      setWithdrawData({
        id: id,
        accountNumber: accountNumber,
        withdrawalAmount: withdrawalAmount,
        accountBalance: accountBalance
      });
      // console.log(withdrawData)
      // console.log(accountToApprove)
    }

    const calculateWithdBalance = async ()=> {
      const response = await calculateWithdrawalBalance({ accountNumber: withdrawData.accountNumber, agentEmail: user?.email || '', withdrawalAmount: withdrawData.withdrawalAmount, accountBalance: withdrawData.accountBalance})
      return response
    }


    const ApproveWithdrawal = async (id: string, accountNumber: string) => {
      setProcessApprove(true); // Indicate that the approval process has started
      const approvalWithdrawalData: approveWithdrawal = {
        id: id,
        email: user?.email || '',
        accountNumber: accountNumber
      };
    
      try {
        const approvedResponse = await approve_withdrawal(approvalWithdrawalData);
        if (approvedResponse.message === 'Withdrawal approved successfully') {
          try {
            const calculateBalance = await calculateWithdBalance();
            if (calculateBalance.message === 'Customer balance updated successfully') {
              notify("Withdrawal approved and balance calculated successfully.");
              refreshWithdrawalApprovals();
            } else {
              notify("Withdrawal approved, but balance calculation failed.");
            }
          } catch (balanceError) {
            console.error("Balance calculation error:", balanceError);
            notify("Withdrawal approved, but balance calculation failed. Please try again later.");
          }
        }
      } catch (approveError) {
        console.error("Approval error:", approveError);
        notify("Failed to approve withdrawal. Please try again later.");
      } finally {
        setApproveOpen(false);
        setProcessApprove(false);
      }
    };
    



  const rows = pendingWithdrawals?.map((customer, index) => (
    <Table.Tr key={index}>
      <Table.Td>{customer.accountNumber}</Table.Td>
      <Table.Td>{customer.customer}</Table.Td>
      <Table.Td>{formatDate(customer?.createdAt)}</Table.Td>
      <Table.Td>{customer.phone}</Table.Td>
      {/* <Table.Td>{customer.transactionType}</Table.Td> */}
      <Table.Td>{`${formatCurrency(parseInt(customer.amount), 'GHS')}`}</Table.Td>
      <Table.Td>{customer.agentName}</Table.Td>
      <Table.Td>{customer.agentEmail}</Table.Td>
      <Table.Td>{customer.agentID}</Table.Td>
      <Table.Td><Badge color={customer.approved !== true ? 'yellow': 'green'}>{customer.approved !== true ? 'Pending': 'Approved'}</Badge> </Table.Td>

      <Table.Td style={{ display: 'flex'}}>
      {/* <IconEdit  size={20} /> */}
        <Button size="xs" variant="outline" color="green" style={{ cursor: 'pointer'}} onClick={()=> openApproveModal(customer.id, customer.accountNumber, customer.amount, customer.balance)}> Approve </Button>
        <Button size="xs" ml={5} color="red" style={{ cursor: 'pointer'}} onClick={()=> openDeleteApproveModal(customer.id, customer.accountNumber, customer.amount, customer.balance)}> Decline </Button>
        </Table.Td>
    </Table.Tr>
  ));


  // useEffect(() => {
  //   refreshWithdrawalApprovals();
  // }, []);

  const filterData = () => {
    const filtered = pendingWithdrawals?.filter((client) =>
      client.customer.includes(search)
    );
    setPendingWithdrawals(filtered);
  };

  const [activePage, setActivePage] = useState(1);

  return (
    <div>
        <ApproveModal title={ <Title order={5}>Approve Withdrawal</Title>} isLoading={processApprove} proceed={()=> ApproveWithdrawal( withdrawData.id, accountToApprove)} onClose={()=> setApproveOpen(false)}
      opened={ApproveOpen} content={`Are you sure you want to approve withdrawal for account number. ${accountToApprove}`} />

<DeleteModal title={ <Title order={5}>Decline Withdrawal</Title>} isLoading={deleteApproveLoading} proceed={()=> deletePendingWithdrawal( withdrawData.id, accountToApprove)} onClose={()=> setDeleteApproveOpen(false)}
      opened={deleteApproveOpen} content={`Are you sure you want to decline withdrawal for account number. ${accountToApprove}`} />
 
 <Paper p={20}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div>
          <Title order={4} mt={20}> Withdrawal </Title>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <InputText
          width="30%"
          value={search}
          type="text"
          name="search"
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Enter customer name"
        />
        <Button
        className="removeOnMobile"
          variant="default"
          color="#274941"
          onClick={filterData}
          style={{ marginInline: 10 }}
        >
          {" "}
          Filter by customer
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          leftSection={<IconRefresh size={16} />}
          ml={5}
          className=""
            variant=""
            color="#000"
            onClick={() => refreshWithdrawalApprovals()}
          >
            Refresh
          </Button>
      </div>

      <div className="tableOnMobile">
      <Table stripedColor='#f2f2f2' highlightOnHover highlightOnHoverColor='#f4f3ef'  striped mt={30}>
        <Table.Thead style={{ backgroundColor: '#fafafa'}}>
          <Table.Tr>
            <Table.Th style={{ fontWeight: 'bold'}}>Account No</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Customer</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}> Date</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}> Phone</Table.Th>
            {/* <Table.Th>Transaction Type</Table.Th> */}
            <Table.Th style={{ fontWeight: 'bold'}}>Amount</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Agent</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Agent Email</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Agent ID</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Status</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Action</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', margin: 20 }}>
      <Pagination 
      color="green"
        value={activePage}
        onChange={setActivePage}
        total={Math.ceil(pendingWithdrawals.length / 3)} />
        </div>
        </Paper>
    </div>
  );
}
