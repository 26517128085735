import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_ENCRPTY_SMS_KEY || ''; 

const encryptValue = (value: string): string => {
  return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
};

const decryptValue = (ciphertext: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const isBase64 = (value: string) => /^[A-Za-z0-9+/]+={0,2}$/.test(value);
const isEncrypted = (value: string): boolean => {
  if (typeof value !== 'string' || value.length < 16) {
    return false; // Too short or invalid type.
  }

  // Check for Base64 format
  if (!isBase64(value)) {
    return false; // Not Base64, likely not encrypted.
  }

  try {
    const decrypted = decryptValue(value);
    return !!decrypted && decrypted !== value; // Decrypted result must be valid and different.
  } catch (error) {
    return false; // Decryption failed, not encrypted.
  }
};

export {
  encryptValue,
  decryptValue,
  isEncrypted
}