import { Alert, Text} from '@mantine/core'
import React from 'react'
import { formatDate } from '../utils/formatDate'
import { useOrgInfo } from '../context/orgContext'
import { IconInfoCircle } from '@tabler/icons-react'

interface infoProp{
  info: string
}
export default function InfoBanner({info}: infoProp) {
    const {createdAt}  = useOrgInfo()
//     const dateOnboarded = formatDate(createdAt)
// const currentDate = new Date();
// const dateCreate = new Date(dateOnboarded)
// const daysLeft = currentDate.getDay() - dateCreate.getDay()

  return (
    <Alert icon={<IconInfoCircle />} color="red" style={{borderRadius: 'none', padding: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
    <small>{info}</small>
  </Alert>
  )
}

