import { useUser } from "../context/UserContext";
import { auth } from "../firebase";
import { getDomainFromEmail } from "../utils/getUserDomain";
import notify from "../utils/notify";


const getAgents =  async (email: string) => {
  if(email !== ''){
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if(!response.ok){
        throw new Error('Something went wrong getting users')
      }
      const data = await response.json();
      
      // if(data){
      //   const users = data?.filter((user: any) => 
      //     user.email.includes(getDomainFromEmail(auth.currentUser?.email || ''))
      //   );
      // return users
      // }
      return data
    }catch(err){
      // console.error('Error getting user:', err);
      return []
    }
  }
  
  };

  const weeklyMetrics =  async (email: string) => {
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/weeklyTransactionMetrics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if(!response.ok){
        throw new Error('Something went wrong getting metrics')
      }
      const data = await response.json();
      return data
    }catch(err){
      // console.error('Error getting metrics:', err);
      return []
    }
  };

  const statistics =  async (email: string) => {
    try{
      const response = await fetch(' https://us-central1-digicollectapp.cloudfunctions.net/reportStatistics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if(!response.ok){
        throw new Error('Something went wrong getting metrics')
      }
      const data = await response.json();
      return data
    }catch(err){
      // console.error('Error getting metrics:', err);
      return []
    }
  };


  interface userUpdateProp {
    email: string
    displayName: string
     role: string | null
     phoneNumber: string
  }
  const updateUser =  async ({email, displayName, role, phoneNumber }: userUpdateProp) => {
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/userAccountUpdate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, displayName, role, phoneNumber  }),

      })
      if(!response.ok){
        throw new Error('Something went wrong getting users')
      }
      const data = await response.json();
      return data
    }catch(err){
      return err
    }
  };

  const disableUser =  async (email: string) => {
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/disable_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),

      })
      if(!response.ok){
        throw new Error('Something went wrong getting users')
      }
      const data = await response.json();
      return data.message
    }catch(err){
      // console.error(err);
      return err
    }
  };
  const enableUser =  async (email: string) => {
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/enable_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),

      })
      if(!response.ok){
        throw new Error('Something went wrong getting users')
      }
      const data = await response.json();
      return data.message
    }catch(err){
      // console.error(err);
      return err
    }
  };


  const getCustomers =  async (email: string) => {
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/customers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if(!response.ok){
        throw new Error('Something went wrong getting users')
      }
      const data = await response.json();
      return data
    }catch(err){
      // console.error(err);
      return []
    }
  };

  const getLoans =  async (email: string) => {
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/loans', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if(!response.ok){
        throw new Error('Something went wrong getting users')
      }
      const data = await response.json();
      return data
    }catch(err){
      // console.error(err);
      return []
    }
  };

  
  const getPendingCustomers =  async (email: string) => {
    if(email !== ''){
      try{
        const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/pending_customers', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
        if(!response.ok){
          throw new Error('Something went wrong getting users')
        }
        const data = await response.json();
        return data
      }catch(err){
        return []
      }
    }
  
  };

  const getPendingWithdrawals =  async (email: string) => {
    if(email !== ''){
      try{
        const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/pending_withdrawals', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
        if(!response.ok){
          throw new Error('Something went wrong getting users')
        }
        const data = await response.json();
        return data
      }catch(err){
        // console.error(err);
        return []
      }
    }
  
  };


  const totalDailyBalance =  async (email: string) => {
    try{
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/daily_total_balance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if(!response.ok){
        throw new Error('Something went wrong getting users')
      }
      const data = await response.json();
      return data
    }catch(err){
      // console.log(err)
      return err
    }
  };


export interface createCustomerProp{
  fullName: string
  loanAmount: number,
  interestRate: string,
  depositAmount: string
   accountNumber: string
   phone: string
   accountBalance: string
    agentEmail: string
    address: string
     DOB: string
     gender: string
     agentName: string 
     agentID: string
     nationalId: string
     savingsType: string
     updatedAt?: string
}

export interface requestLoanProp{
  accountNumber: string
  loanAmount: string
  interestRete: string
    agentEmail: string
     agentName: string 
     agentID: string
}

// const request_loan = async ({ accountNumber, agentEmail, agentID, agentName}: requestLoanProp): Promise<string> => {
//   try {
//     const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ accountNumber, agentEmail, agentID, agentName }),
//     });

//     if (!response.ok) {
//       // console.log(response)
//       throw new Error(response.statusText);
//     }

//     const data = await response.json();
//     return data.message
//   } catch (error: any) {
//     console.error('Error creating customer:', error);
//     return error
//   }
// };



  const create_Customer = async ({ fullName, loanAmount, interestRate, phone, agentEmail, accountNumber , accountBalance, depositAmount, address, DOB, gender, agentID, agentName, nationalId,
    savingsType}: createCustomerProp): Promise<any> => {
    // e.preventDefault();
    try {
      const response = await fetch(
        // 'http://localhost:3001/create-customer',
        'https://us-central1-digicollectapp.cloudfunctions.net/create_customer', 
        {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fullName, loanAmount, interestRate, phone, agentEmail, accountNumber , accountBalance, depositAmount, address, DOB, gender, agentID, agentName, nationalId,
          savingsType }),
      });

      if (!response.ok) {
        if(response.status === 426){
          notify('Upgrade plan to add more customers')
          // throw new Error('Upgrade your plan to process more transactions');
      }
        throw new Error(response.statusText);
        
      }

      const data = await response.json();
      return data.message
    } catch (error: any) {
      // console.error('Error creating customer:', error);
      return error
    }
  };




  export interface createUserType {
    email: string,
    password: string,
    reEnterPassword?: any,
    displayName: string,
    companyName?: string,
    companyEmail?: string
    phoneNumber: string,
    agentID: string,
    role: string | null
    orgId?: string
  }
  
  
  const createUser = async ({email, password, displayName, companyEmail, companyName, phoneNumber, role, agentID, orgId }: createUserType) => {
    // e.preventDefault();
    try {
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/createOrgUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, displayName, companyEmail, companyName, phoneNumber, role, agentID, orgId }),
      });

      // if (!response.ok) {
      //   throw new Error('Oops! something went wrong. Error creating user');
      // }
      const data = await response.json();
      return data.message
    } catch (error: any) {
      if(error.code === 'auth/invalid-phone-number'){
        return('Invalid phone number');
      }
      if (error.code === 'auth/invalid-phone-number') {
        return('Invalid phone number format. Must be a non-empty E.164 compliant string.');
    }
      // console.error('Error creating user:', error.message);
      return error.message
    }
  };

  const handleExport = async (data: object) => {
    try {
      // Make an API call to download the Excel file
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/exportData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({data}),
      });
  
      // Check if the response is ok (status code 200-299)
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      // Convert the response to a blob
      const blob = await response.blob();
  
      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.xlsx'); // Specify the file name
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // console.error('Error downloading file:', error);
    }
  };


  interface uploadType{
    file: File | null
    email: string
  }

  const uploadCustomerData = async (data: uploadType) => {
    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);
    }
    formData.append('email', data.email);
    // console.log(data.file, data.email)
  
    try {
      const response = await fetch('https://upload-customer-data-ff86abddc96b.herokuapp.com/upload', {
        method: 'POST',
        body: formData,
      });
      return response;
    } catch (err) {
      return err;
    }
  }

 const saveProfileInfo =  async (uid: string | undefined, downloadURL: string, fullName: string, phone: string) => {
      try{
        const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/update_profile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uid: uid,
            photoURL: downloadURL,
            fullName: fullName,
            phoneNumber: phone
          }),
        });
        // console.log(response)
        return response
      }catch(err){
        // console.log(err)
       return err
      }
    
  };

  export interface deleteCustomerProp{
    agentEmail: string
    accountNumber: string
  }

  export interface deletePendingWithdrawalProp{
    id: string
    agentEmail: string
    accountNumber: string
  }

  export interface ApproveCustomer{
    email: string
    accountNumber: string
  }

  export interface approveWithdrawal{
    id: string
    email: string
    accountNumber: string
  }


  const delete_customer = async ({agentEmail, accountNumber }: deleteCustomerProp) => {
    // e.preventDefault();
    try {
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/delete_customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ agentEmail, accountNumber }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong please try again later');
      }

      const data = await response.json();
      return data
    } catch (error) {
      // console.error('Error creating user:', error);
      return error
    }
  };

  const delete_pending_withdrawal = async ({id, agentEmail, accountNumber }: deletePendingWithdrawalProp) => {
    // e.preventDefault();
    try {
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/delete_withdrawal_approval', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, agentEmail, accountNumber }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong please try again later');
      }

      const data = await response.json();
      return data
    } catch (error) {
      // console.error('Error creating user:', error);
      return error
    }
  };


  const approve_customer = async ({ email,  accountNumber }: ApproveCustomer) => {
    // e.preventDefault();
    try {
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/approve_customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, accountNumber }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong please try again later');
      }

      const data = await response.json();
      return data
    } catch (error) {
      // console.error('Error approving customer:', error);
      return error
    }
  };


  const get_orgInfo = async (email: string) => {
    try {
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/get_org_info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong please try again later');
      }

      const data = await response.json();
      return data
    } catch (error) {
      // console.error('Error getting org Info:', error);
      return error
    }
  };


  const approve_withdrawal = async ({id, email,  accountNumber }: approveWithdrawal) => {
    // e.preventDefault();
    try {
      const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/approve_withdrawal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id, email, accountNumber }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong please try again later');
      }

      const data = await response.json();
      return data
    } catch (error) {
      // console.error('Error approving withdrawal:', error);
      return error
    }
  };

  interface calculateWithdrawalBalanceProp{
    agentEmail: string,
   accountNumber: string,
   withdrawalAmount: string,
   accountBalance: string
  }

  interface calculateReturnProp{
    message: string
}

  const calculateWithdrawalBalance = async ({ agentEmail, accountNumber, withdrawalAmount, accountBalance}: calculateWithdrawalBalanceProp): Promise<calculateReturnProp>=> {
    return new Promise(async (resolve, reject) => {
    try{
        const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/calculate_withdrawal_balance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ agentEmail, accountNumber, withdrawalAmount, accountBalance }),
        })
        if(!response.ok){
          throw new Error('Error: Something went wrong')
        }
        const data = await response.json();
        resolve(data)
      }catch(err){
        // console.error(err);
      }
  }
)}


const topUpSMSAfterPurchase = async (email: string, smsNumber: number) => {
  try{
    const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/smsTopUp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, smsNumber }),
    })
    if(!response.ok){
      throw new Error('Something went wrong updating sms')
    }
    const data = await response.json();
    return data.message
  }catch(err){
    // console.error(err);
    return []
  }
};

export interface sendSMSProp{
  message: string
  to: string[]
}

const sendSMS = (message: string, to: string[]) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await fetch(`https://api.mnotify.com/api/sms/quick?key=manjfYVu4LYiKtuqJLNkNJfQo`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  recipient: to,
                  "sender": "DigiCollect",
                  message: message,
                  "is_schedule": "false",
                  "schedule_date": ""
              })
          }); 
          if (!response.ok) {
              // console.log(response)
              throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          resolve(jsonData);
      } catch (error) {
          reject(error);
      }
  });
};

       const editTransaction = async (email: string, transactionId: string, newDate: string) => {
        try{
          const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/edit_transaction', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, transactionId, newDate }),
          })
          if(!response.ok){
            throw new Error('Something went wrong updating sms')
          }
          const data = await response.json();
          return data.message
        }catch(err){
          // console.error(err);
          return []
        }
      };




      interface saveTransactionType{
        transId: string
        customer : string
        accountNumber: string
         phone: string
         balance: number
         agentEmail: string
         amount: number
         agentName: string
         agentID: string
         date: string
         transactionType: string
         approved: boolean
         accountingDate: string
    }
    
    interface saveTransaProp{
        message: string
        requireUpgrade?: boolean
    }
    
    const saveTransaction = async ({
      transId,
      customer,
      approved,
      accountNumber,
      phone,
      balance,
      agentEmail,
      amount,
      agentName,
      agentID,
      transactionType,
      accountingDate
    }: saveTransactionType): Promise<saveTransaProp> => {
      
      
      return new Promise(async (resolve, reject) => {
        try {
          const currentUser = auth.currentUser;
    
          if (!currentUser) {
            throw new Error('User not authenticated');
          }
    
          // Get the ID token from Firebase Authentication
          const token = await currentUser.getIdToken(true).then((idToken)=> idToken);
          const response = await fetch(
            // 'http://localhost:3001/saveTransaction',
            'https://us-central1-digicollectapp.cloudfunctions.net/save_transaction',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, 
              },
              body: JSON.stringify({
                transId: transId,
                customer: customer,
                accountNumber: accountNumber,
                phone: phone,
                balance: balance,
                agentEmail: agentEmail,
                amount: amount,
                agentName: agentName,
                agentID: agentID,
                transactionType: transactionType,
                approved: approved,
                accountingDate: accountingDate
              }),
            }
          );
    
          if (!response.ok) {
            if(response.status === 401){
                return {message: 'Unauthorized user'};
            }
            if(response.status === 426){
              // notify('')
              resolve({message: 'Upgrade your plan to record more transactions', requireUpgrade: true})
              // throw new Error('Upgrade your plan to process more transactions');
          }
            throw new Error('Ops! something went wrong, try again later');
          }
    
          const jsonData = await response.json();
          resolve(jsonData);
        } catch (error) {
          console.log(error)
          reject(error);
        }
      });
    };
    

    interface calculateDepositBalanceProp{
      agentEmail: string,
     accountNumber: string,
     depositAmount: number,
     accountBalance: number
    }

    const calculateDepositBalance = async ({agentEmail, accountNumber, depositAmount, accountBalance}: calculateDepositBalanceProp)=> {
      try{
          const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/calculate_deposit_balance', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ agentEmail, accountNumber, depositAmount, accountBalance }),
          })
          if(!response.ok){
            throw new Error('Something went wrong getting users')
          }
          const data = await response.json();
          return data
        }catch(err){
          console.error('Error getting user:', err);
          return []
        }
    }
    
    const smsDeduct = (email: string, smsUsed: number ) => {
      return new Promise(async (resolve, reject) => {
          try {
              const response = await fetch('https://us-central1-digicollectapp.cloudfunctions.net/smsDeduct', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ email, smsUsed })
              }); 
              if (!response.ok) {
                  console.log(response.status)
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              resolve(data);
          } catch (error) {
              reject(error);
          }
      });
    };


  export {
    getAgents,
    createUser,
    disableUser,
    getCustomers, 
    create_Customer,
    delete_customer,
    totalDailyBalance,
    approve_customer,
    getPendingCustomers,
    getPendingWithdrawals,
    approve_withdrawal,
    calculateWithdrawalBalance,
    delete_pending_withdrawal,
    saveProfileInfo,
    enableUser,
    handleExport,
    uploadCustomerData,
    get_orgInfo,
    topUpSMSAfterPurchase,
    getLoans,
    weeklyMetrics,
    sendSMS,
    statistics,
    editTransaction,
    updateUser,
    saveTransaction,
    calculateDepositBalance,
    smsDeduct
    // request_loan
  }

