// Effective Date: [Insert Date]
const privacyPolicy = `
At DigiCollect, your privacy is a priority. This Privacy Policy outlines how we collect, 
use, and protect your business information when you use our platform. By accessing or 
using DigiCollect, you agree to the terms of this Privacy Policy.

**1. Information We Collect**  
We may collect the following types of information:

  a. **Personal Information**  
    - Name  
    - Email address  
    - Phone number  
    - Financial details, such as account information

  b. **Business Information**  
    - Organization details (e.g., name, address, phone number).  
    - Documents uploaded for KYC purposes (e.g., certificates of incorporation, tax clearance certificates, and trading licenses).  

  c. **Usage Information**  
    - IP address, device information, browser type, and interaction data with the platform.  
    - Log data, including timestamps and activity logs.  

  d. **Transactional Data**  
    - Records of deposits, withdrawals, and other customer interactions logged on the platform.  

**2. How We Use Your Information**  
We use the information we collect for the following purposes:  
  - **Platform Operation**: To provide, maintain, and improve the DigiCollect platform.  
  - **Security**: To authenticate users, prevent fraud, and protect the integrity of our systems.  
  - **Support**: To communicate with you regarding your account, transactions recorded, or inquiries.  
  - **Compliance**: To fulfill legal obligations, such as KYC and regulatory requirements.  
  - **Analytics**: To understand platform usage and improve user experience.  

**3. How We Share Your Information**  
We do not sell or rent your personal information to third parties. However, we may share information in the following scenarios:  
  - **With Your Consent**: When you explicitly allow us to share your data.  
  - **Service Providers**: With trusted third parties that help us provide our services (e.g., cloud storage, analytics tools).  
  - **Legal Obligations**: When required by law, such as to comply with court orders or legal investigations.  
  - **Business Transfers**: In the event of a merger, acquisition, or sale of DigiCollect, your data may be transferred as part of the transaction.  

**4. Data Retention**  
We retain your information for as long as necessary to provide our services, comply with legal obligations, or resolve disputes. 
When data is no longer needed, we securely delete or anonymize it.  

**5. Security Measures**  
We implement industry-standard security measures to protect your information, including:  
  - Data encryption during transmission and storage.  
  - Regular security audits and vulnerability testing.  
  - Access controls to restrict unauthorized access to sensitive data.  

**6. Your Rights**  
Depending on your location, you may have the following rights regarding your personal information:  
  - **Access**: Request access to the data we hold about you.  
  - **Correction**: Update or correct inaccurate information.  
  - **Deletion**: Request the deletion of your data, subject to legal obligations.  
  - **Objection**: Opt out of certain uses of your data, such as marketing communications.  

To exercise your rights, please contact us at info@digicollect.app.  

**7. Cookies and Tracking Technologies**  
DigiCollect uses cookies and similar technologies to enhance user experience and gather usage statistics. You can manage your cookie preferences through your browser settings.  

**8. Third-Party Links**  
Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices of these external sites.  

**9. Changes to This Privacy Policy**  
We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes.  

**11. Contact Us**  
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:  
  - **Email**: info@digicollect.app  
  - **Address**: Tse-Addo (Greater Accra) 

This Privacy Policy reflects DigiCollect's commitment to protecting your privacy while enabling seamless micro-finance management.
`;

export { privacyPolicy };

// **9. International Users**  
// If you access DigiCollect from outside [Insert Primary Country], your information may be transferred to and stored in our servers in [Insert Primary Country].  