import React, { useEffect, useState } from 'react';
import './App.css';
import AppRouter from './routes/AppRouter'
import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/charts/styles.css';
import { ToastContainer } from 'react-toastify';
import { UserProvider } from './context/UserContext';

const theme = createTheme({
  /** Put your mantine theme override here */
});

function App() {
  return (
  <MantineProvider>
    <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={true} 
        newestOnTop={true} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover/>
    <UserProvider>
      <AppRouter />
    </UserProvider>
    </MantineProvider>
 
  );
}


export default App;
