import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ROUTES } from "./const";
import Authentication from "../views/auth";
import { DashboardSkeleton } from "../components/Appshell";
import LandingHome from "../views/landingHome";
import VerifyEmail from "../views/verifyEmail";
import LandingAbout from "../views/landingAbout";
import NotFound from "../views/notFound";
import FAQ from "../views/faq";
import Features from "../styles/features";
import ProtectedRoute from "./protectedRoute";
import { AdminStatusProvider } from "../context/adminContext";
import { AgentsProvider } from "../context/agentsContext";
import { DailyTotalBalanceProvider } from "../context/TotalDailyTransactionAmountContext";
import { RecentTransactionsProvider } from "../context/recentTransactionsContext";
import { DailyTransactionsCountProvider } from "../context/TotalDailyTransactionCount";
import { CustomerDataProvider } from "../context/customersContext";
import { LoanDataProvider } from "../context/LoanContext";
import { CustomerApprovalProvider } from "../context/customerApprovalContext";
import { WithdrawalApprovalProvider } from "../context/withdrawalApprovalContext";
import { TransactionDataProvider } from "../context/TransactionsContext";
import { WeeklyMetricsProvider } from "../context/weeklyTransactionMetricsContext";
import { StatisticsProvider } from "../context/reportContext";
import { OrgInfoProvider } from "../context/orgContext";
import LandingPricing from "../views/landingPricing";

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.landing} element={<LandingHome />} />
        <Route path={ROUTES.landing + "about"} element={<LandingAbout />} />
        <Route path={ROUTES.landing + "FAQ"} element={<FAQ />} />
        <Route path={ROUTES.landing + "features"} element={<Features />} />
        <Route path={ROUTES.landing + "pricing"} element={<LandingPricing />} />
        <Route path={ROUTES.landing} element={<LandingHome />} />
        <Route path={ROUTES.verifyEmail} element={<VerifyEmail />} />
        <Route path={ROUTES.login} element={<Authentication />} />
        <Route
          path={ROUTES.home}
          element={
            <AdminStatusProvider>
              <AgentsProvider>
                <ProtectedRoute>
                  <OrgInfoProvider>
                    <DailyTotalBalanceProvider>
                      <RecentTransactionsProvider>
                        <DailyTransactionsCountProvider>
                          <CustomerDataProvider>
                            <LoanDataProvider>
                              <CustomerApprovalProvider>
                                <WithdrawalApprovalProvider>
                                  <TransactionDataProvider>
                                    <WeeklyMetricsProvider>
                                      <StatisticsProvider>
                                        <DashboardSkeleton />
                                      </StatisticsProvider>
                                    </WeeklyMetricsProvider>
                                  </TransactionDataProvider>
                                </WithdrawalApprovalProvider>
                              </CustomerApprovalProvider>
                            </LoanDataProvider>
                          </CustomerDataProvider>
                        </DailyTransactionsCountProvider>
                      </RecentTransactionsProvider>
                    </DailyTotalBalanceProvider>
                  </OrgInfoProvider>
                </ProtectedRoute>
              </AgentsProvider>
            </AdminStatusProvider>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
