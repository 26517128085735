import React, { createContext, useContext, useState, useEffect, ReactElement } from 'react';
import { auth, db } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import useAdminOrgId from '../hooks/useAdminOrgId';


interface OrgInfoProp {
  id: any;
  companyName: string;
  companyEmail: string
  companyNumber: string;
  createdAt: any
  orgId: string;
  sms: {purchased: number, balance: number};
  generateAccNo: boolean;
  plan: { createdAt: any, monthlyChargeDue: number, payAsYouGo: boolean, planExpired: boolean}
  monthlyChargeDue: number
  customerAccountTypes: string[]
}

interface OrgInfoContextType {
  companyName: string;
  companyEmail: string
  companyNumber: string;
  createdAt: any
  orgId: string;
  sms: {purchased: number, balance: number};
  generateAccNo: boolean;
  plan: { createdAt: any, monthlyChargeDue: number, payAsYouGo: boolean,  planExpired: boolean}
    monthlyChargeDue: number
    customerAccountTypes: string[]
  refresh: () => void;
}

const OrgInfoContext = createContext<OrgInfoContextType | null>(null);

export const OrgInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  const [orgInfo, setOrgInfo] = useState<OrgInfoProp | null>(null);
  const [customerAccountTypes, setCustomerAccountTypes] = useState<string[]>([])
  const organizationId = useAdminOrgId();

  const getOrgInfo = () => {
    if (!organizationId) return;
  
    try {
      const q = query(collection(db, "orgInfo"), where("orgId", "==", organizationId));
  
      // Listen for real-time updates
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]; // Assuming you want the first document only
          const orgInformation: OrgInfoProp = {
            id: doc.id,
            ...doc.data(),
          } as OrgInfoProp;
          setOrgInfo(orgInformation);
          setCustomerAccountTypes(orgInformation.customerAccountTypes);
        } else {
          console.warn("No organization found for the given orgId");
          setOrgInfo(null);
        }
      });
  
      // Return unsubscribe function if needed to stop listening later
      return unsubscribe;
    } catch (error: any) {
      console.error("Error setting up real-time listener for orgInfo:", error);
      alert("Error setting up real-time updates for org info");
      setOrgInfo(null);
    }
  };

  useEffect(() => {
    const unsubscribe = getOrgInfo();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [organizationId]);

  return (
    <OrgInfoContext.Provider
      value={{
        companyName: orgInfo?.companyName || '',
        companyEmail: orgInfo?.companyEmail || '',
        companyNumber: orgInfo?.companyNumber || '',
        createdAt: orgInfo?.createdAt || '',
        orgId: orgInfo?.orgId || '',
        sms: { purchased: orgInfo?.sms.purchased || 0, balance: orgInfo?.sms.balance || 0 },
        plan: { createdAt: orgInfo?.plan.createdAt, monthlyChargeDue: orgInfo?.plan.monthlyChargeDue || 0,  payAsYouGo: orgInfo?.plan.payAsYouGo || false,  planExpired: orgInfo?.plan.planExpired || false},
        monthlyChargeDue: orgInfo?.monthlyChargeDue || 0,
        customerAccountTypes: orgInfo?.customerAccountTypes || customerAccountTypes,
        generateAccNo: orgInfo?.generateAccNo || false,
        refresh: getOrgInfo,
      }}
    >
      {children}
    </OrgInfoContext.Provider>
  );
};

export const useOrgInfo = () => {
  const context = useContext(OrgInfoContext);
  if (!context) {
    throw new Error('useOrgInfo must be used within an OrgInfoProvider');
  }
  return context;
};
